"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var utils_1 = require("./lib/utils");
var dom_ready_1 = require("./dom-ready");
var Video_1 = require("./Video");
var menu_1 = require("./menu");
var Carousel_1 = require("./Carousel");
var Accordion_1 = require("./Accordion");
var toggleSublevelMobile_1 = require("./toggleSublevelMobile");
(0, dom_ready_1.domReady)(function () {
    (0, menu_1.initMenu)();
    (0, menu_1.initStickyHeader)();
    initBundles();
    (0, Video_1.initVideos)();
    (0, Carousel_1.initCarousels)();
    (0, Accordion_1.initAccordions)();
    (0, toggleSublevelMobile_1.initToggleSublevelMobile)();
});
/**
 * Code Splitting / Lazy loading JS Resources
 */
function initBundles() {
    var selectorsForLegacyWebComponents = [
        // Nasicher
        // '',
        // Common
        'oebb-fm-app-form',
        'oebb-fm-form',
    ];
    var selectorsForWebComponents = [
        'oebbx-gallery-slider',
        'oebbx-gallery-thumbs',
        'oebbx-gallery-item',
    ];
    if (document.querySelector(selectorsForLegacyWebComponents.join(','))) {
        (0, utils_1.loadScript)('/.resources/nasicher-2021/themes/js/oebb-web-components.js');
    }
    if (document.querySelector(selectorsForWebComponents.join(','))) {
        (0, utils_1.loadScript)('/.resources/nasicher-2021/themes/js/oebb-web-components/oebb-web-components.esm.js', 'oebb-web-components-module', null, 'module');
        (0, utils_1.loadScript)('/.resources/nasicher-2021/themes/js/oebb-web-components/oebb-web-components.js', 'oebb-web-components-nomodule', null, 'nomodule');
    }
    if (document.querySelector('.js-apprenticeships')) {
        (0, utils_1.loadScript)('/.resources/nasicher-2021/themes/js/apprenticeships.min.js');
    }
}
